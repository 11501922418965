import React from "react"
import {
  AccordionData,
  AccordionItemData,
  CardData,
  ColumnsData,
  ColumnItemData,
  CustomComponentData,
  CTAData,
  CTAGroupData,
  HeroAnimationData,
  HeroData,
  HorizontalDividerData,
  ImageData,
  JSONComponent,
  LogoSetData,
  LogoSetItemData,
  MarketoFormData,
  MediaImageData,
  SectionData,
  SectionDecoratorData,
  TitleAndBodyData,
  VideoData,
  WYSIWYGData,
  BasicContentData,

  /* PLOP_INJECT_IMPORT */
  QuoteData,
  QuoteSectionData,
  BulletColumnData,
  AnimationVideoData,
} from "dataComponents"

const resolve = entity => {
  if (entity?.__typename === "Drupal_ParagraphAccordionItem") {
    return <AccordionItemData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphAccordionSection") {
    return <AccordionData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphCard") {
    return <CardData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphCustomComponent") {
    return <CustomComponentData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphHorizontalDivider") {
    return <HorizontalDividerData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_MediaImage") {
    return <MediaImageData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphColumnItem") {
    return <ColumnItemData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphColumns") {
    return <ColumnsData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphCta") {
    return <CTAData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphCtaGroup") {
    return <CTAGroupData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphEventsSectionDecoration") {
    return <SectionDecoratorData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphTrustPageSection") {
    return <SectionData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphHeroAnimation") {
    return <HeroAnimationData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphHero") {
    return <HeroData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphHeroEvents") {
    return <HeroData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphImage") {
    return <ImageData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphLogoSetSection") {
    return <LogoSetData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphLogoSetItem") {
    return <LogoSetItemData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphMarketoForm") {
    return <MarketoFormData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphTitleAndBody") {
    return <TitleAndBodyData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphVidyard") {
    return <VideoData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphHtml") {
    return <WYSIWYGData component={entity} key={entity.entityUuid} />
  }
  if (entity?.__typename === "Drupal_ParagraphBasicContent") {
    return <BasicContentData component={entity} key={entity.entityUuid} />
  }

  /* PLOP_INJECT_RESOLVER */

  if (entity?.__typename === "Drupal_ParagraphQuote") {
    return <QuoteData component={entity} key={entity.entityUuid} />
  }

  if (entity?.__typename === "Drupal_ParagraphQuotes") {
    return <QuoteSectionData component={entity} key={entity.entityUuid} />
  }

  if (entity?.__typename === "Drupal_ParagraphBulletColumn") {
    return <BulletColumnData component={entity} key={entity.entityUuid} />
  }

  if (entity?.__typename === "Drupal_ParagraphAnimation") {
    return <AnimationVideoData component={entity} key={entity.entityUuid} />
  }

  return <JSONComponent component={entity} />
}

const componentResolver = data => {
  const components = []

  if (!data) {
    return []
  }

  if (!Array.isArray(data)) {
    components.push(resolve(data.entity))
  } else {
    data.forEach(({ entity }) => {
      components.push(resolve(entity))
    })
  }

  return components
}

export default componentResolver
