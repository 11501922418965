import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import axios from "axios"
import { SystemStatus } from "components"

const SystemStatusData = () => {
  const tooltip =
    "Because Okta's architecture is highly multi-tenant and includes a large set of use cases, products and geographical regions, the availability displayed here is an average of Okta service incidents that affected at least 10% of customers and is not intended to be representative of each customer's specific service availability."
  const cta = { label: "View system status", link: "https://status.okta.com/" }

  const subtitle = {
    label: "12-Month Availability:",
    percent: "99.99%",
  }

  return (
    <SystemStatus
      tooltip={tooltip}
      cta={cta}
      subtitle={subtitle}
    />
  )
}

SystemStatusData.propTypes = {}

export const SystemStatusDataQuery = graphql`
  fragment SystemStatusDataQuery on PARAGRAPH_TYPE {
    entityUuid
  }
`

export default SystemStatusData
