import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { CVEListing } from "components"

const CVEListingData = () => {
    const data = useStaticQuery( graphql`
        query {
            allContentfulSecurityAdvisories(
              sort: { order: DESC, fields: datePosted }
              filter: {
                childrenContentfulSecurityAdvisoriesShortDescriptionTextNode: {}
                node_locale: { eq: "en" }
              }
            ) {
              nodes {
                url
                title
              }
            }
        }
    `
    )
    const advisories = [...data.allContentfulSecurityAdvisories.nodes]

    return (
        <CVEListing
            advisories={ advisories }
        />
    )
}

CVEListingData.propTypes = {}

export const CVEListingDataDataQuery = graphql`
  fragment CVEListingDataQuery on Drupal_NodeTrustSecurityAdvisory {
    __typename
    path_alias
    title
    fieldHidden
  }
`

export default CVEListingData
