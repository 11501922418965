import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { HeadingTag, WYSIWYG } from "components"
import { componentResolver } from "helpers"

function TitleAndBodyData({ component }) {
  return (
    <React.Fragment>
      {component.fieldTitle && <HeadingTag align={component.fieldTitleAlignment} display={component.fieldTitleDisplay} text={component.fieldTitle} tag={component.fieldTitleTag} overrideStyles={{
        mb: ["spacing4", null, null, "spacing6"]
      }} />}
      {component.fieldBody &&
        <WYSIWYG content={component.fieldBody?.value} />
      }

    </React.Fragment>
  )
}

TitleAndBodyData.propTypes = {
}

export const TitleAndBodyDataQuery = graphql`
  fragment TitleAndBodyDataQuery on Drupal_ParagraphTitleAndBody {
    __typename
    entityUuid
    fieldTitle
    fieldTitleAlignment
    fieldTitleDisplay
    fieldTitleTag
    fieldBody {
      value
    }
  }
`
export default TitleAndBodyData
