/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { schemaOrgWebPage, schemaEvent, schemaOrganization } from "./Schema.js"

function SEO({ lang, metaTags, translations, title }) {
  const hreflangData = translations.map(translation => ({
    rel: "alternate",
    hreflang: translation.langcode.value,
    href: translation.path_alias,
  }))

  const linkData = metaTags
    .filter(meta => meta.__typename === "Drupal_MetaLink")
    .map(meta => ({ rel: meta.key, href: meta.value }))
    .concat(hreflangData)

  const metaData = metaTags
    .filter(
      meta =>
        meta.__typename === "Drupal_MetaValue" ||
        meta.__typename === "Drupal_MetaProperty"
    )
    .map(meta => ({ name: meta.key, content: meta.value }))
  const metaTitle = metaData.filter(meta => meta.name === "title").shift()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle?.content || title}
      link={linkData}
      meta={metaData}
    >
      {
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      }

      {
        <script type="application/ld+json">
          {JSON.stringify(schemaEvent)}
        </script>
      }

      {
        <script type="application/ld+json">
          {JSON.stringify(schemaOrganization)}
        </script>
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  metaTags: [],
  translations: [],
  title: "",
}

SEO.propTypes = {
  lang: PropTypes.string,
  metaTags: PropTypes.arrayOf(PropTypes.object),
  translations: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
}

export const Metatag = graphql`
  fragment Metatag on Drupal_Metatag {
    __typename
    key
    value
  }
`

export default SEO
