/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useCookies } from "react-cookie"
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "@reach/router"
import { getVisitorLocation } from "helpers"
// import { Modal } from "components"

export const LanguageContext = React.createContext({ lang: "en", languages: [] })

const LanguageProvider = ({ children, lang }) => {
  const [currentContextLanguages, setCurrentContextLanguages] = useState([])
  const [currentLang, setCurrentLang] = useState(lang)
  const [modalState, setModalState] = useState(null)
  const [cookies, setCookie] = useCookies(null)
  const languages = useStaticQuery(graphql`
    {
      drupal {
        taxonomyTermQuery(
          filter: {
            conditions: [{ operator: EQUAL, field: "vid", value: ["languages"] }]
          }
          sort: { field: "weight", direction: ASC }
        ) {
          entities {
            entityTranslations {
              entityLabel
              entityLanguage {
                id
              }
              # ... on Drupal_TaxonomyTermLanguages {
              #   fieldLanguageMapping {
              #     entity {
              #       entityId
              #       entityLabel
              #     }
              #   }
              # }
            }
          }
        }
      }
    }
  `)

  const setLanguages = () => {
    const formattedLanguages = []
    let translation

    languages.drupal.taxonomyTermQuery.entities.forEach((language) => {
      const isCurrentLanguage = language.entityTranslations.some((item) => {
        return !item.fieldLanguageMapping.entity
          ? currentLang
          : item.fieldLanguageMapping.entity.entityId === currentLang
      })

      if (isCurrentLanguage) {
        return
      }

      translation = language.entityTranslations.find(
        (item) => item.entityLanguage.id === currentLang
      )

      const hasTranslation =
        translation !== undefined && translation.fieldLanguageMapping.entity

      if (!hasTranslation) {
        translation = language.entityTranslations.find(
          (item) => item.entityLanguage.id === "en"
        )
      }

      const selectedLanguage =
        translation.fieldLanguageMapping.entity.entityId === "en"
          ? ""
          : translation.fieldLanguageMapping.entity.entityId.toLowerCase()

      const label = hasTranslation
        ? translation.entityLabel
        : translation.fieldLanguageMapping.entity.entityLabel

      formattedLanguages.push({
        link: selectedLanguage,
        label,
        image:
          translation?.fieldImage?.entity?.gatsbyImageFile?.childImageSharp?.gatsbyImageData?.src,
      })
    })

    return setCurrentContextLanguages(formattedLanguages)
  }

  const hasLanguageTranslations = () => {
    return (
      languages.drupal.taxonomyTermQuery.entities.length > 0 &&
      languages.drupal.taxonomyTermQuery.entities[0].entityTranslations.length > 0
    )
  }

  const getLangIsoCodes = () => {
    const langs = [...languages.drupal.taxonomyTermQuery.entities]
    return langs.map((entity) => {
      const fakeEntity = [...entity.entityTranslations]
      const langId = fakeEntity.shift().fieldLanguageMapping.entity
        ? fakeEntity.shift().fieldLanguageMapping.entity.entityId
        : "en"
      return langId
    })
  }

  const validateCookies = async () => {
    if (!cookies.okta_ip2l || !cookies.okta_ip2l_c) {
      const visitorData = await getVisitorLocation()
      setCookie("okta_ip2l", visitorData, { path: "/" })
      setCookie("okta_ip2l_c", visitorData.country.iso_code.toLowerCase(), {
        path: "/",
      })
    }
  }

  useEffect(() => {
    if (hasLanguageTranslations() && !modalState) {
      const langsIsoCode = getLangIsoCodes()
      validateCookies()
      if (
        !cookies.okta_no_redirect &&
        langsIsoCode.indexOf(cookies.okta_ip2l_c) > 0 &&
        currentLang !== cookies.okta_ip2l_c
      ) {
        setModalState(true)
      }
      setLanguages()
    }
  }, [cookies])

  const handleRedirect = () => {
    setCookie("okta_no_redirect", true, { path: "/" })
    setCurrentLang(cookies.okta_ip2l_c)
    navigate(`/${cookies.okta_ip2l_c}`)
  }

  const handleModalState = () => {
    setModalState(false)
    setCookie("okta_no_redirect", true, { path: "/" })
  }

  return (
    <LanguageContext.Provider
      value={{
        lang: currentLang,
        languages: currentContextLanguages,
      }}
    >
      {children}
      {/*hasLanguageTranslations() && modalState && (
        <Modal
          title='Are you visiting www.okta.com from outside the US?'
          handleCloseModal={handleModalState}
          handleAcceptModal={handleRedirect}
        >
          Visit your regional site for more relevant pricing, promotions and events.
        </Modal>
      )*/}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string.isRequired,
}

export default LanguageProvider
