import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { QuoteSection } from "components"
import { componentResolver } from "helpers"

const QuoteSectionData = ({ component }) => {
  const components = componentResolver(component.fieldParagraphs)
  return (
    <QuoteSection>
      {components &&
        components.map(item => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
    </QuoteSection>
  )
}

QuoteSectionData.propTypes = {}

// This will need to be updated when the backend is ready
export const QuoteSectionDataQuery = graphql`
  fragment QuoteSectionDataQuery on Drupal_ParagraphQuotes {
    __typename
    entityUuid
    fieldParagraphs {
      entity {
        entityUuid
        ...QuoteDataQuery
      }
    }
  }
`

export default QuoteSectionData
