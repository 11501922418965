import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { Card } from "components"

const CardData = ({ component }) => {
  return (
    <Card
      body={component.fieldBody?.value}
      ctaUrl={component.fieldCta?.uri}
      ctaText={component.fieldCta?.title}
      kicker={component.fieldTitleSuper}
      tag={component.fieldHeaderTag}
      title={component.fieldTitle}
    />
  )
}

CardData.propTypes = {
}

export const CardDataQuery = graphql`
  fragment CardDataQuery on Drupal_ParagraphCard {
    __typename
    entityUuid
    fieldTitleSuper
    fieldTitle
    fieldHeaderTag
    fieldBody {
      value
    }
    fieldCta {
      title
      uri
    }
  }
`

export default CardData
