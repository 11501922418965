/** @jsx jsx */
import { jsx } from "theme-ui"
import { React, useEffect } from "react"
import PropTypes from "prop-types"
import { WYSIWYG } from "components"

const Video = ({ video, chat, caption }) => {
  // If link is from vimeo, get id and check if it's a chat or normal video
  let { vimeoUrl, vidyardId, vimeoId } = "";

  const getVideoType = () => {
    if (video.indexOf("vimeo") > -1) {
      return "vimeo";
    } else if (video.indexOf("vidyard") > -1) {
      return "vidyard";
    }
    return;
  }

  const videoType = getVideoType();

  useEffect(() => {
    const VidyardAPI = require("@vidyard/embed-code");
    if (VidyardAPI !== null) {
      VidyardAPI.api.renderDOMPlayers();
    }
  });

  if (videoType === "vimeo") {
    if (!chat) {
      const vimeoRegEx = /(videos|video|channels|\.com)\/([\d]+)/;
      vimeoId = video.match(vimeoRegEx)[2];
    }
    vimeoUrl = chat ? video : `https://player.vimeo.com/video/${vimeoId}`;
  }

  if (videoType === "vidyard") {
    const vidyardRegEx = /^http.*watch\/?([a-zA-Z0-9]*)/g;
    vidyardId = vidyardRegEx.exec(video)[1];
  }

  return (
    <div>
      <div>
        {videoType == "vimeo" &&
          <div sx={{position: "relative", width: "100%", height: ["350px", null, null, "0px"], pb: [0, null, null, "56.25%"]}}>
            <iframe
              src={vimeoUrl}
              frameBorder="0"
              width="100%"
              height="100%"
              allowFullScreen
              sx={{position: "absolute", top: 0, bottom: 0, left: 0, width: "100%", height: "100%"}}>
            </iframe>
          </div>
        }
        {videoType == "vidyard" && vidyardId !== "" &&
          <img
            alt="Vidyard video"
            className="vidyard-player-embed"
            data-type="inline"
            data-uuid={vidyardId}
            data-v="4"
            src={`https://play.vidyard.com/${vidyardId}.jpg`}
            sx={{ width: "100%", margin: "auto", display: "block" }}
          />
        }
      </div>
      {
        caption && <div sx={{ mt:"spacing2" }}>
          <WYSIWYG content={caption} />
        </div>
      }
    </div>
  )
}

Video.propTypes = {
}

export default Video
