import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { FormMarketo } from "components"

const MarketoFormData = ({ component }) => {
  return (
    <FormMarketo
      formId={component.fieldMarketoFormId}
      cid={component.fieldMarketoCampaignId}
      thankYouMessage={component.fieldBody?.value}
      thankYouURL={component.fieldUrl?.uri}
     />
  )
}

MarketoFormData.propTypes = {
}

export const MarketoFormDataQuery = graphql`
  fragment MarketoFormDataQuery on Drupal_ParagraphMarketoForm {
    __typename
    entityUuid
    fieldBody {
      value
    }
    fieldMarketoCampaignId
    fieldMarketoFormId
    fieldUrl {
      title
      uri
    }
  }
`

export default MarketoFormData
