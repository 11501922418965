import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { CTA, LoginButton, RegistrationButton } from "components"

function CTAData({ component }) {
  if (component.fieldModalId?.toLowerCase() === "login") {
    return (
      <LoginButton
        triggerElement="button"
        buttonSize={component.fieldCtaSize}
        buttonStyle={component.fieldCtaStyle}
        customContent={component.fieldColumns}
        title={component.fieldCta?.title}
      />
    )
  }

  if (component.fieldModalId?.toLowerCase() === "register") {
    return (
      <RegistrationButton
        triggerElement="button"
        buttonSize={component.fieldCtaSize}
        buttonStyle={component.fieldCtaStyle}
        customContent={component.fieldColumns}
        title={component.fieldCta?.title}
      />
    )
  }

  return (
    <CTA
      href={component.fieldCta?.uri}
      size={component.fieldCtaSize}
      style={component.fieldCtaStyle}
      text={component.fieldCta?.title}
     />
  )
}

CTAData.propTypes = {
}

export const CTADataQuery = graphql`
  fragment CTADataQuery on Drupal_ParagraphCta {
    __typename
    entityUuid
    fieldCta {
      uri
      title
    }
    fieldCtaSize
    fieldCtaStyle
    fieldModalId
    fieldColumns {
      entity {
        ... on Drupal_ParagraphColumns {
          __typename
          entityUuid
          fieldColumnsHorizontalAlign
          fieldColumnsVerticalAlign
          fieldParagraphs {
            entity {
              entityUuid
              ... on Drupal_ParagraphColumnItem {
                __typename
                entityUuid
                fieldColumnItemWidth
                fieldParagraphs {
                  entity {
                    entityUuid
                    ...AccordionDataQuery
                    ...CardDataQuery
                    ...TitleAndBodyDataQuery
                    ...VideoDataQuery
                    ...WYSIWYGDataQuery
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CTAData
