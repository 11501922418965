import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { Sponsor } from "components"

const LogoSetItemData = ({ component }) => {
  return (
    <Sponsor logo={component?.fieldMediaImage?.entity?.gatsbyImageFile} description={component.fieldBody?.value} />
  )
}

LogoSetItemData.propTypes = {
}

export const LogoSetItemDataQuery = graphql`
  fragment LogoSetItemDataQuery on Drupal_ParagraphLogoSetItem {
    __typename
    entityUuid
    fieldBody {
      value
    }
    fieldMediaImage {
      entity {
        ...MediaImageDataQuery
      }
    }
  }
`

export default LogoSetItemData
