/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Container, Columns, ColumnItem, CTA, HeadingTag, Image, WYSIWYG } from "components"
import HeroVideoMP4 from "../../media/videos/Europe_Hero_Animation_updated.mp4"
import HeroVideoWEBM from "../../media/videos/Europe_Hero_Animation_updated.webm"
import HeroVideoPoster from "../../media/videos/frame.png"

const HeroAnimation = ({ alt = "Forum21 Europe", bgColor, body, children, image, subtitle, title }) => {
  const bodyStyles = {
    mb: "spacing4",
    maxWidth: "600px",
  }

  const ctaStyles = {
    mt: "spacing2"
  }

  const imgStyles = {
    mb: "spacing3",
    maxWidth: "450px",
  }

  const subtitleStyles = {
    mt: "spacing4",
    mb: "spacing2",
    maxWidth: "100%"
  }

  const titleStyles = {
    mb: "spacing1"
  }

  return (
    <div
      sx={{
        variant: "sections.primaryHero",
        alignItems: "center",
        display: "flex",
        flexDirection: ["column", null, null, "row"],
        justifyContent: "center",
        minHeight: [0, null, null, "600px"],
        position: "relative",
        overflow: "hidden",
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
          bg: "#FCE8C5",
        },
      }}
    >
      <Container overrideStyles={{
        order: [2, null, null, 1],
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
          ml: 0
        },
      }}>
        <Columns horizontalAlignment="flex-start">
          <ColumnItem width="50" overrideStyles={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            py: ["spacing6", null, null, "spacing8"],
          }}>
            <HeadingTag text="Forum21 Europe" tag="h1" overrideStyles={{ fontSize: "0px !important", }} />
            {image && <div sx={{ ...imgStyles }}>
              <Image src={image} alt={alt} width="413" height="171" />
            </div>}
            {title && <div sx={{ ...titleStyles }}><HeadingTag text={title?.text} tag={title?.tag} display={title?.display} /></div>}
            {subtitle && <div sx={{ ...subtitleStyles }}><HeadingTag text={subtitle?.text} tag={subtitle?.tag} display="title4Medium" /></div>}
            {body && <div sx={{ ...bodyStyles, }}><WYSIWYG content={body} /></div>}
            {children && <div sx={{ ...ctaStyles }}>{children}</div>}
          </ColumnItem>
        </Columns>
      </Container>

      <div sx={{
        position: ["relative", null, null, "absolute"],
        order: [1, null, null, 2],
        height: ["150px", null, null, "auto"],
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "flex-end",
        zIndex: 0,
      }}>
        <div sx={{
          display: ["flex", null, null, "block"],
          justifyContent: "center",
          alignItems: "center",
          flex: ["1 1 100%", null, null, "0 1 50%"],
          maxWidth: ["100%", null, null, "50%"],
          overflow: ["hidden", null, null, "auto"],
        }}>
          <video width="320" height="240" muted loop autoPlay playsInline preload="true" poster={HeroVideoPoster} sx={{
            display: "block",
            ml: "auto",
            height: ["auto", null, null, "100%"],
            width: ["100%", null, null, "auto"],
            flex: "none",
            position: "relative",
            zIndex: "1",
          }}>
            <source src={HeroVideoMP4} type="video/mp4" />
            <source src={HeroVideoWEBM} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  )
}

HeroAnimation.propTypes = {
}

export default HeroAnimation
