/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

const QuoteIcon = () => (
  <svg width="70" height="59" viewBox="0 0 70 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path sx={{
      fill: "turquois100",
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        fill: "#ABD5D6",
      }
    }} d="M12.9611 33.8101H26.3515V59.0004H0V33.8101C0 17.8554 5.39375 6.66565 21.1456 0L26.7541 10.1189C15.5372 15.0713 12.9611 21.5228 12.9611 33.8101ZM55.6817 33.8101H69.0722V59.0004H42.7475V33.8101C42.7206 17.8554 48.1144 6.66565 63.8663 0L69.5015 10.1189C58.2847 15.0713 55.6817 21.5228 55.6817 33.8101Z"  />
  </svg>
)

QuoteIcon.propTypes = {
}

export default QuoteIcon

