import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { Video } from "components"

const VideoData = ({ component }) => {
  return (
    <Video video={component.video} caption={component.caption?.value} chat={component.chat} />
  )
}

VideoData.propTypes = {
}

export const VideoDataQuery = graphql`
  fragment VideoDataQuery on Drupal_ParagraphVidyard {
    __typename
    uuid
    video: fieldVidyardShareUrl
    caption: fieldBody {
      value
    }
  }
`

export default VideoData
