import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Section } from "components"
import { componentResolver } from "helpers"

function SectionData({ component }) {
  const components = componentResolver(component.fieldParagraphs)
  const decorations = componentResolver(component.fieldPageSectionDecorations)
  return (
    <Section
      backgroundColor={component.fieldEventsBackgroundColor}
      container={true}
      paddingTop={component.fieldSectionTopPadding}
      paddingBottom={component.fieldSectionBottomPadding}
      id={component.fieldSectionIdAnchor}
      dividers={component.fieldSectionDividers}
    >
      {components &&
        components.map(item => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}

      {decorations &&
        decorations.map(item => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
    </Section>
  )
}

SectionData.propTypes = {}

export const SectionDataQuery = graphql`
  fragment SectionDataQuery on Drupal_ParagraphTrustPageSection {
    __typename
    entityUuid
    fieldEventsBackgroundColor
    fieldSectionIdAnchor
    fieldSectionTopPadding
    fieldSectionBottomPadding
    fieldSectionDividers
    fieldPageSectionDecorations {
      entity {
        ...SectionDecoratorDataQuery
      }
    }
    fieldParagraphs {
      entity {
        entityUuid
        ...ColumnsDataQuery
        ...HorizontalDividerDataQuery
        ...TitleAndBodyDataQuery
        ...BasicContentDataQuery
        ...CustomComponentDataQuery
        ...QuoteSectionDataQuery
      }
    }
  }
`

export default SectionData
