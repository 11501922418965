import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { HeroAnimation } from "components"
import { componentResolver } from "helpers"

function HeroAnimationData({ component = {} }) {

  const components = componentResolver(component.fieldCtaGroup)

  return (
    <HeroAnimation
      body={component.fieldBody?.value}
      image={component?.fieldMediaImage?.entity?.gatsbyImageFile}
      subtitle={{
        text: component.fieldSubtitle,
        tag: "h4"
      }}
      title={{
        display: component.fieldTitleDisplay,
        text: component.fieldTitle,
        tag: "h1"
      }}
    >
      {
        components &&
        components.map((item) => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })
      }
    </HeroAnimation>
  )
}

HeroAnimationData.propTypes = {
}

export const HeroAnimationDataQuery = graphql`
  fragment HeroAnimationDataQuery on Drupal_ParagraphHeroAnimation {
    __typename
    fieldBody {
      value
    }
    fieldCtaGroup {
      entity {
        entityUuid
        ...CTAGroupDataQuery
      }
    }
    fieldMediaImage {
      entity {
      ...MediaImageDataQuery
      }
    }
    fieldSubtitle
    fieldTitle
    fieldTitleDisplay
  }
`

export default HeroAnimationData
