import React from "react"
import PropTypes from "prop-types"
import { useForm, FormProvider } from "react-hook-form"
import { useStaticQuery, graphql } from "gatsby"
import { LanguageContext } from "./LanguageProvider"

function FormsProvider({ children }) {
  // const formLabels = useStaticQuery(graphql`
  //   {
  //     drupal {
  //       siteSettingEntityQuery {
  //         entities {
  //           ... on Drupal_SiteSettingEntityFormLabels {
  //             fieldLabels {
  //               entity {
  //                 entityTranslations {
  //                   ... on Drupal_ParagraphKeyValuePair {
  //                     fieldHeader
  //                     fieldBody {
  //                       value
  //                     }
  //                     entityLanguage {
  //                       id
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //             fieldLegal {
  //               entity {
  //                 entityTranslations {
  //                   ... on Drupal_ParagraphKeyValuePair {
  //                     fieldHeader
  //                     fieldBody {
  //                       value
  //                     }
  //                     entityLanguage {
  //                       id
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  const getLabels = (currentLang) => {
    const labelObject = {}
    formLabels.drupal.siteSettingEntityQuery.entities.forEach((entity) => {
      if (!entity.fieldLabels) {
        return
      }

      entity.fieldLabels.forEach((label) => {
        let selectedLabel = label.entity.entityTranslations.find(
          (item) => item.entityLanguage.id === currentLang
        )

        if (!selectedLabel) {
          selectedLabel = label.entity.entityTranslations.find(
            (item) => item.entityLanguage.id === "en"
          )
        }
        labelObject[selectedLabel.fieldHeader] = selectedLabel.fieldBody.value
      })
    })
    return labelObject
  }

  const getLegal = (currentLang) => {
    const legalObject = {}
    formLabels.drupal.siteSettingEntityQuery.entities.forEach((entity) => {
      if (!entity.fieldLegal) {
        return
      }

      entity.fieldLegal.forEach((legalItem) => {
        let legalTranslation = legalItem.entity.entityTranslations.find(
          (item) => item.entityLanguage.id === currentLang
        )

        if (!legalTranslation) {
          legalTranslation = legalItem.entity.entityTranslations.find(
            (item) => item.entityLanguage.id === "en"
          )
        }
        legalObject[legalTranslation.fieldHeader] = legalTranslation.fieldBody.value
      })
    })
    return legalObject
  }

  const { register, errors, handleSubmit, watch } = useForm()

  return (
    <LanguageContext.Consumer>
      {({ lang }) => (
        <FormProvider
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          watch={watch}
          labels={getLabels(lang)}
          legal={getLegal(lang)}
        >
          {children}
        </FormProvider>
      )}
    </LanguageContext.Consumer>
  )
}

FormsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FormsProvider
