import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { AnimationVideo } from "components"

const AnimationVideoData = ({ component }) => {
  const mp4URL = component.fieldAnimationReference.entity.fieldAnimationMp4Url || component.fieldAnimationReference.entity.fieldMediaVideoFile1.entity.url;
  const webmURL = component.fieldAnimationReference.entity.fieldAnimationWebmUrl || component.fieldAnimationReference.entity.fieldAnimationWebm.entity.url;

  return (
    <AnimationVideo
      mp4={mp4URL}
      poster={component.fieldAnimationReference.entity.fieldMediaFile.entity.url}
      webm={webmURL}
    />
  )
}

AnimationVideoData.propTypes = {
}

export const AnimationVideoDataQuery = graphql`
  fragment AnimationVideoDataQuery on Drupal_ParagraphAnimation {
    __typename
    entityUuid
    fieldAnimationReference {
      entity {
        ... on Drupal_MediaAnimation {
          fieldAnimationWebmUrl
          fieldAnimationMp4Url
          fieldMediaFile {
            entity {
              url
            }
          }
          fieldAnimationWebm {
            entity {
              url
            }
          }
          fieldMediaVideoFile1 {
            entity {
              url
            }
          }
        }
      }
    }
  }
`

export default AnimationVideoData
