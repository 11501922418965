/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { CheckmarkIcon } from "components"

const BulletColumn = ({ content }) => {
  return (
    <div sx={{
      display: "flex"
    }}>
      <div sx={{
        pr: "spacing3"
      }}>
        <CheckmarkIcon />
      </div>
      <div>
        <p>{content}</p>
      </div>
    </div>
  )
}

BulletColumn.propTypes = {
}

export default BulletColumn
