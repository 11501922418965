import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { BulletColumn } from "components"

const BulletColumnData = ({ component }) => {
  console.log({component})
  return (
    <div>
      BulletColumnData
      <BulletColumn content={component.content.processed} />
    </div>
  )
}

BulletColumnData.propTypes = {
}

export const BulletColumnDataQuery = graphql`
  fragment BulletColumnDataQuery on PARAGRAPH_TYPE {
    entityUuid
    content: field_body {
      processed
    }
  }
`

export default BulletColumnData
