/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const AnimationVideo = ({ mp4, poster, webm, overrideStyles }) => {
  return (
    <video width="320" height="240" muted loop autoPlay playsInline preload="true" poster={poster} sx={{
      display: "block",
      ml: "auto",
      height: ["auto", null, null, "100%"],
      width: ["100%", null, null, "auto"],
      maxWidth: "100%",
      flex: "none",
      position: "relative",
      zIndex: "1",
      ...overrideStyles
    }}>
      <source src={mp4} type="video/mp4" />
      <source src={webm} type="video/webm" />
      Your browser does not support the video tag.
    </video>
  )
}

AnimationVideo.propTypes = {
}

export default AnimationVideo
