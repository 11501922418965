import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Hero } from "components"
import { componentResolver } from "helpers"

function HeroData({ component = {} }) {
  const components = componentResolver(component.fieldCtaGroup)

  return (
    <Hero
      body={component.fieldBody?.value}
      image={component?.fieldMediaImage?.entity?.gatsbyImageFile}
      bgColor="primary"
      subtitle={{
        text: component.fieldSubtitle,
        tag: "h4"
      }}
      title={{
        display: component.fieldTitleDisplay,
        text: component.fieldTitle,
        tag: "h1"
      }}
    >
      {
        components &&
        components.map((item) => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })
      }
    </Hero>
  )
}

HeroData.propTypes = {
}

export const HeroDataQuery = graphql`
  fragment HeroDataQuery on Drupal_ParagraphHeroEvents {
    __typename
    fieldBody {
      value
    }
    fieldCtaGroup {
      entity {
        entityUuid
        ...CTAGroupDataQuery
      }
    }
    fieldMediaImage {
      entity {
      ...MediaImageDataQuery
      }
    }
    fieldSubtitle
    fieldTitle
    fieldTitleDisplay
  }
`

export default HeroData
