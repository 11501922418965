import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { Accordion } from "components"
import { componentResolver } from "helpers"

const AccordionData = ({ component }) => {
  const items = componentResolver(component?.fieldAccordionItems)
  return (
    <Accordion>
      {items &&
        items.map((item) => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
    </Accordion>
  )
}

AccordionData.propTypes = {
}

export const AccordionDataQuery = graphql`
  fragment AccordionDataQuery on Drupal_ParagraphAccordionSection {
    __typename
    entityUuid
    fieldAccordionItems {
      entity {
        ...AccordionItemDataQuery
      }
    }
  }
`

export default AccordionData
