import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { componentResolver } from "helpers"
import { LogoSetGroup } from "components"

const LogoSetData = ({ component }) => {
  const components = componentResolver(component.fieldLogoSetItems)
  return (
    <LogoSetGroup
      title={component.fieldTitle}
      logoSize={component.logoSize}
      tag={component.tag}
    >
      {components &&
        components.map((item) => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
    </LogoSetGroup>
  )
}

LogoSetData.propTypes = {
}

export const LogoSetDataQuery = graphql`
  fragment LogoSetDataQuery on Drupal_ParagraphLogoSetSection {
    __typename
    entityUuid
    fieldTitle
    logoSize: fieldLogoSetTileSize
    tag: fieldHeaderTag
    fieldLogoSetItems {
      entity {
        ...LogoSetItemDataQuery
      }
    }
  }
`

export default LogoSetData
