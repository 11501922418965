import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Quote } from "components"

const QuoteData = ({ component }) => {
  return (
    <Quote
      body={component.body.processed}
      byline={component.byline}
      cta={component.cta}
    />
  )
}

QuoteData.propTypes = {}

// This will need to be updated when the backend is ready
export const QuoteDataQuery = graphql`
  fragment QuoteDataQuery on Drupal_ParagraphQuote {
    __typename
    entityUuid
    body: fieldBody {
      processed
    }
    byline: fieldByline
    cta: fieldCta {
      title
      uri
    }
  }
`

export default QuoteData
