/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Image, ModalTrigger, WYSIWYG } from "components"

const Sponsor = ({ fromQuery, logo, alt = "", description }) => {
  const imageWrapperStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bg: "primary",
    p: "spacing2",
    aspectRatio: "1",
    objectFit: "contain",
  }

  const imageStyle = {
    display: "block",
    maxWidth: "100%",
    width: "100%",
  }

  const modalContent = <div className="Logo-set-item-image-wrapper" sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: "spacing2",
      }}>
        <div sx={{
          ...imageWrapperStyles,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "250px",
          width: "100%",
        }}>
          <Image src={logo} alt={alt} overrideStyles={imageStyle} />
        </div>

        <div sx={{ display: "block", mt: "spacing4" }}>
          {description && <WYSIWYG content={description} />}
        </div>
      </div>
  return (
    <ModalTrigger
      triggerElement="div"
      modalContent={modalContent}>
      <div className="Logo-set-item-image-wrapper" sx={{
        ...imageWrapperStyles,
        cursor: "pointer",
        transform: "scale(1)",
        transition: "transform 0.25s ease",

        "&:hover": {
          transform: "scale(1.05)",
        }
      }}>
        <Image src={logo} alt={alt} overrideStyles={imageStyle} />
      </div>
    </ModalTrigger>
  )
}

Sponsor.propTypes = {
  alt: PropTypes.string,
  img: PropTypes.string,
}

export default Sponsor
