/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const CheckmarkIcon = ({ color = "#00D1B3" }) => (
<svg width="20" height="14" fill="none">
  <path d="M1 7l6 6L19 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>
)

CheckmarkIcon.propTypes = {
  color: PropTypes.string,
}

export default CheckmarkIcon
