import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

function MediaImageData({ component }) {
  return (
    <div>MediaImageData</div>
  )
}

MediaImageData.propTypes = {
}

export const MediaImageDataQuery = graphql`
  fragment MediaImageDataQuery on Drupal_MediaImage {
    __typename
    uuid
    entityLabel
    fieldMediaImage {
      url
    }
    gatsbyImageFile {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

export default MediaImageData
