import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { CTAGroup } from "components"
import { componentResolver } from "helpers"

function CTAGroupData({ component }) {
  const components = componentResolver(component.fieldParagraphs)
  return (
    <CTAGroup
      flexDirection={component.flexDirection}
      alignItems={component.alignItems}
    >
      {components &&
        components.map(item => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
    </CTAGroup>
  )
}

CTAGroupData.propTypes = {}

export const CTAGroupDataQuery = graphql`
  fragment CTAGroupDataQuery on Drupal_ParagraphCtaGroup {
    __typename
    entityUuid
    alignItems: fieldCtaGroupAlignItems
    flexDirection: fieldCtaGroupDirection
    fieldParagraphs {
      entity {
        entityUuid
        ...CTADataQuery
      }
    }
  }
`

export default CTAGroupData
