/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { CustomLink, QuoteIcon } from "components"

const Quote = ({ body, byline, cta }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 50%",
        py: ["spacing5", null, "spacing8"],
        px: ["spacing4", null, "spacing6"],
      }}
    >
      <QuoteIcon />
      <div sx={{ mt: "spacing5" }}>
        <blockquote
          dangerouslySetInnerHTML={{ __html: body }}
          sx={{
            m: 0,
            mb: "spacing4",
            p: {
              variant: "text.title4",
            },
          }}
        />
      </div>
      <cite
        className="Quote-byline"
        sx={{
          mt: "auto",
          py: "spacing1",
          fontStyle: "normal",
        }}
      >
        {byline}
      </cite>
      {cta && (
        <div
          sx={{
            mt: "spacing6",
          }}
        >
          <CustomLink href={cta.uri}>{cta.title}</CustomLink>
        </div>
      )}
    </div>
  )
}

Quote.propTypes = {}

export default Quote
