/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Quote } from "components"

const QuoteSection = ({ children }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", null, "row"],

        "& > div": {
          bg: "gray100",

          ".Quote-byline": {
            borderTop: "1px solid",
            borderTopColor: "primary",
            borderBottom: "1px solid",
            borderBottomColor: "primary",
          },

          "&:first-child": {
            bg: "primary",
            color: "white",

            "blockquote *": {
              color: "white",
            },

            a: {
              color: "white",
              "border-bottom-color": "white"
            },

            ".Quote-byline": {
              borderTop: "1px solid",
              borderTopColor: "white",
              borderBottom: "1px solid",
              borderBottomColor: "white",
            },
          },
        },
      }}
    >
      {children}
    </div>
  )
}

QuoteSection.propTypes = {}

export default QuoteSection
