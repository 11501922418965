/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
import { AccordionItem, HeadingTag } from "components";

const Accordion = ({ children }) => {
  return (
    <div sx={{ width: "100%" }}>
      {children}
    </div>
  )
}

Accordion.propTypes = {
}

export default Accordion
