/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Columns, ColumnItem, HeadingTag } from "components"

const LogoSetGroup = ({ children, title, logoSize, tag }) => {
  const calcLogoSize = () => {
    if (logoSize === "small") {
      return {
        px: "spacing1",
        pt: "spacing1",
        pb: "spacing1",
        width: ["25%", null, null, "20%", "15%"],
        maxWidth: ["25%", null, null, "20%", "15%"],
        minWidth: ["25%", null, null, "20%", "15%"],
      }
    }

    if (logoSize === "medium") {
      return {
        px: ["spacing1", null, null, "spacing2"],
        pt: ["spacing1", null, null, "spacing2"],
        pb: ["spacing1", null, null, "spacing2"],
        width: ["33%", null, null, "25%"],
        maxWidth: ["33%", null, null, "25%"],
        minWidth: ["33%", null, null, "25%"],
      }
    }

    return {
      px: ["spacing2", null, null, "spacing3"],
      pt: ["spacing2", null, null, "spacing3"],
      pb: ["spacing2", null, null, "spacing3"],
      width: ["50%", null, null, "33%"],
      maxWidth: ["50%", null, null, "33%"],
      minWidth: ["50%", null, null, "33%"],
    }
  }

  const styles = calcLogoSize()

  return (
    <div sx={{
      display: "flex",
      flexDirection: "column",
      m: "auto",
      maxWidth: "928px",
    }}>
      {title &&
        <div sx={{
          mb: ["spacing3", null, null, "spacing5"],
          textAlign: "center"
        }}>
          <div><HeadingTag text={title} tag={tag} /></div>
        </div>
      }
      <Columns horizontalAlignment="center" verticalAlignment="center" >
        { children.map((item, i) => {
          return (
            <div sx={{
              mx: "0",
              ...styles
            }} key={i}>
              {item}
            </div>
          )
        })}
      </Columns>
    </div>
  )
}

LogoSetGroup.propTypes = {
}

export default LogoSetGroup
