import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { ColumnItem } from "components"
import { componentResolver } from "helpers"

function ColumnItemData({ component }) {
  const components = componentResolver(component.fieldParagraphs)
  // console.log("components: ", components)
  return (
    <ColumnItem width={component.fieldColumnItemWidth}>
      {components &&
        components.map((item) => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
    </ColumnItem>
  )
}

ColumnItemData.propTypes = {
}

export const ColumnItemDataQuery = graphql`
  fragment ColumnItemDataQuery on Drupal_ParagraphColumnItem {
    __typename
    entityUuid
    fieldColumnItemWidth
    fieldParagraphs {
      entity {
        entityUuid
        ...AccordionDataQuery
        ...AnimationVideoDataQuery
        ...CardDataQuery
        ...CTAGroupDataQuery
        ...CustomComponentDataQuery
        ...ImageDataQuery
        ...LogoSetDataQuery
        ...MarketoFormDataQuery
        ...NestedColumnsDataQuery
        ...TitleAndBodyDataQuery
        ...VideoDataQuery
        ...WYSIWYGDataQuery
        ...BasicContentDataQuery
      }
    }
  }
`
export default ColumnItemData
