import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { SectionDecorator } from "components"

function SectionDecoratorData({ component }) {
  return <SectionDecorator position={component.position} type={component.type} />
}

SectionDecoratorData.propTypes = {
}

export const SectionDecoratorDataQuery = graphql`
  fragment SectionDecoratorDataQuery on Drupal_ParagraphEventsSectionDecoration {
    __typename
    entityUuid
    type: fieldEventsDecorationType
    position: fieldEventsDecorationPlace
  }
`

export default SectionDecoratorData
