import React from "react"
import PropTypes from "prop-types"

function JSONComponent({ component }) {
  return (
    <pre>
      <code>{JSON.stringify(component, {}, 2)}</code>
    </pre>
  )
}

JSONComponent.propTypes = {
  component: PropTypes.shape({}).isRequired,
}

export default JSONComponent
