const getVisitorLocation = () => {
  let geoip2 = window.geoip2 || undefined
  return new Promise((resolve, reject) => {
    if (typeof geoip2 !== "undefined") {
      const onSuccess = (location) => {
        console.log("location: ", location)
        resolve(location)
      }
      const onError = (error) => {
        console.log("location error: ", error)
        reject(error)
      }
      geoip2.city(onSuccess, onError)
    }
  })
}

export default getVisitorLocation
