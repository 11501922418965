/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"

const HorizontalDivider = ({ color = "primary" }) => {
  return (
    <div sx={{ p: "spacing2", display: "flex", justifyContent: "center" }}>
      <div sx={{ width: "100%", maxWidth: "80px", height: 0, borderBottom: "2px solid", borderBottomColor: color }} />
    </div>
  )
}

HorizontalDivider.propTypes = {
}

export default HorizontalDivider
