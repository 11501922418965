import React from "react"
import PropTypes from "prop-types"
import { Layout, Modal, SEO } from "components"
import { componentResolver } from "helpers"

const Page = ({ data, location, pageContext }) => {
  // console.log("Page: ", data)
  const { page } = data.drupal
  const components = componentResolver(page?.fieldContent)
  const hero = componentResolver(page?.fieldHero)
  const metatags = page?.entityMetatags
  const translations = page?.entityTranslations

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title="Okta | The Identity Standard"
        metaTags={metatags}
        translations={translations}
      />
      {hero &&
        hero.map(item => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
      {components &&
        components.map(item => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
    </Layout>
  )
}

Page.propTypes = {}

export default Page
