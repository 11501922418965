import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Columns } from "components"
import { componentResolver } from "helpers"

function ColumnsData({ component }) {
  // console.log("ColumnsData: ", component)
  const components = componentResolver(component.fieldParagraphs)
  return (
    <Columns horizontalAlignment={component.fieldColumnsHorizontalAlign} verticalAlignment={component.fieldColumnsVerticalAlign}>
      {components &&
        components.map((item) => {
          return (
            <React.Fragment key={item.props.component.entityUuid}>
              {item}
            </React.Fragment>
          )
        })}
    </Columns>
  )
}

ColumnsData.propTypes = {
}

export const ColumnsDataQuery = graphql`
  fragment ColumnsDataQuery on Drupal_ParagraphColumns {
    __typename
    entityUuid
    fieldColumnsHorizontalAlign
    fieldColumnsVerticalAlign
    fieldParagraphs {
      entity {
        entityUuid
        ...ColumnItemDataQuery
      }
    }
  }
`

export default ColumnsData
