import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { HorizontalDivider } from "components"

const HorizontalDividerData = ({ component }) => {
  return (
    <HorizontalDivider />
  )
}

HorizontalDividerData.propTypes = {
}

export const HorizontalDividerDataQuery = graphql`
  fragment HorizontalDividerDataQuery on Drupal_ParagraphHorizontalDivider {
    __typename
    entityUuid
  }
`
export default HorizontalDividerData
