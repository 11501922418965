import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { WYSIWYG } from "components"
import { componentResolver } from "helpers"

function WYSIWYGData({ component }) {
  return (
    <WYSIWYG content={component.fieldBody.value} />
  )
}

WYSIWYGData.propTypes = {
}

export const WYSIWYGDataQuery = graphql`
  fragment WYSIWYGDataQuery on Drupal_ParagraphHtml {
    __typename
    entityUuid
    fieldBody {
      value
    }
  }
`
export default WYSIWYGData
