import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Image } from "components"

const ImageData = ({ component }) => {
  return (
    <Image
      alt={component.fieldMediaImage.entity.fieldMediaImage.alt}
      alignment={component.fieldImageAlignment}
      caption={component.fieldCaption}
      src={component.fieldMediaImage.entity.gatsbyImageFile}
      widthOverride={component.fieldImageWidthOverride}
    />
  )
}

ImageData.propTypes = {}

export const MediaImageQuery = graphql`
  fragment MediaImageQuery on Drupal_MediaImage {
    uuid
    fieldMediaImage {
      alt
      url
    }
    gatsbyImageFile {
      base
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export const ImageDataQuery = graphql`
  fragment ImageDataQuery on Drupal_ParagraphImage {
    __typename
    entityUuid
    fieldImageAlignment
    fieldCaption
    fieldImageWidthOverride
    fieldMediaImage {
      entity {
        ...MediaImageQuery
      }
    }
  }
`

export default ImageData
