import React from "react"
import ReactHtmlParser from "react-html-parser"
import { Image } from "components"

const HtmlParser = (content, inlineImages) => {
  const options = {
    decodeEntities: true,
    transform: (htmlnode) => {
      if (htmlnode.type === "tag" && htmlnode.name === "img") {
        const inlineImage = inlineImages.find((inlineImage) => {
          return htmlnode.attribs.src
            ? htmlnode.attribs.src.includes(inlineImage?.base)
            : htmlnode.attribs["data-src"]
            ? htmlnode.attribs["data-src"].includes(inlineImage?.base)
            : ""
        })

        if (inlineImage) {
          const alt = htmlnode.attribs.alt ? htmlnode.attribs.alt : ""
          const styles = {
            ...(htmlnode.attribs.width && { width: `${htmlnode.attribs.width}px` }),
            ...(htmlnode.attribs.height && {
              height: `${htmlnode.attribs.height}px`,
            }),
          }
          return (
            <Image
              alt={alt}
              imageData={{ gatsbyImageFile: inlineImage }}
              key={inlineImage.base}
              overrideStyles={styles}
            />
          )
        }

        return
      }
    },
  }
  return ReactHtmlParser(content, options)
}

export default HtmlParser
