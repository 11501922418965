import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { BasicContent } from "components"
import { componentResolver } from "helpers"
import { HtmlParser } from "helpers"

const BasicContentData = ({
  component: { title, headerTag, body, ctas, image, inlineImages, imagePosition },
}) => {
  const filteredInlineImages = inlineImages?.filter(node => node !== null)
  const ctaComponents = componentResolver(ctas)
  const hasInlineImages = filteredInlineImages?.length > 0
  const modifiedBodyValue = hasInlineImages
    ? HtmlParser(body?.processed, filteredInlineImages)
    : body?.processed

  return (
    <BasicContent
      title={title}
      headerTag={headerTag}
      body={modifiedBodyValue}
      hasInlineImages={hasInlineImages}
      image={image}
      imagePosition={imagePosition}
    >
      {ctaComponents &&
        ctaComponents.map(item => {
          return <div key={item.props.component.entityUuid}>{item}</div>
        })}
    </BasicContent>
  )
}

BasicContentData.propTypes = {
  component: PropTypes.shape({
    body: PropTypes.shape({
      processed: PropTypes.string,
    }),
    ctas: PropTypes.array,
    entityUuid: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.array,
    imagePosition: PropTypes.string,
  }).isRequired,
}

export const BasicContentDataQuery = graphql`
  fragment BasicContentDataQuery on Drupal_ParagraphBasicContent {
    __typename
    title: fieldTitle
    headerTag: fieldHeaderTag
    body: fieldBody {
      __typename
      processed
      value
    }
    ctas: fieldCtaGroup {
      entity {
        ...CTAGroupDataQuery
      }
    }
    image: fieldMediaImage {
      entity {
        ...MediaImageQuery
      }
    }
    imagePosition: fieldImagePosition
    inlineImages {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

export default BasicContentData
