import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { AccordionItem } from "components"

const AccordionItemData = ({ component }) => {
  return (
    <AccordionItem title={component.fieldTitle} tag={component.fieldHeaderTag} body={component.fieldBody?.value} />
  )
}

AccordionItemData.propTypes = {
}

export const AccordionItemDataQuery = graphql`
  fragment AccordionItemDataQuery on Drupal_ParagraphAccordionItem {
    __typename
    entityUuid
    fieldHeaderTag
    fieldTitle
    fieldBody {
      value
    }
  }
`

export default AccordionItemData
