import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RegistrationForm } from "components"
import { JSONComponent, SystemStatusData, CVEListingData } from "dataComponents"
import { oktaEnvCheck } from "helpers"

const CustomComponentData = ({ component }) => {
  if (component.fieldComponentName === "RegistrationForm") {
    return <RegistrationForm formId={oktaEnvCheck("GATSBY_MARKETO_FORMID")} />
  }

  if (component.fieldComponentName === "TrustSystemStatus") {
    return <SystemStatusData />
  }

  if (component.fieldComponentName === "CVEListing") {
    return <CVEListingData />
  }

  return <JSONComponent component={component} />
}

CustomComponentData.propTypes = {}

export const CustomComponentDataQuery = graphql`
  fragment CustomComponentDataQuery on Drupal_ParagraphCustomComponent {
    __typename
    entityUuid
    fieldComponentName
  }
`

export default CustomComponentData
